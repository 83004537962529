import React from 'react'
import Wrapper from '../../components/wrapper'
import { graphql } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

const Partnery = ({ data }) => {
  data = data.partneryJson
  return (
    <Wrapper title="Партнеры" titleOfPage="Партнеры" descriptionOfPage="Партнеры" keywordsOfPage={['Партнеры']}>
      <section className="partnery">
        <div className="container">
          <ul>
            {data.items.map((item, index) => {
              return (
                <li key={index}>
                  <div>
                    <a href={item.href}>
                      <div className="wrap">
                        <div>
                          <GatsbyImage image={getImage(item.image)} align="middle" alt={`partnery-${index}`} style={{ width: '100%' }} />
                        </div>
                        <p>{item.title}</p>
                      </div>
                    </a>
                  </div>
                </li>
              )
            })}
          </ul>
        </div>
      </section>
    </Wrapper>
  )
}

export default Partnery

export const data = graphql`
  query {
    partneryJson {
      items {
        title
        href
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
    }
  }
`
